import { JaaSMeeting, JitsiMeeting } from "@jitsi/react-sdk";
import { fetchSessionAction } from "actions/sessions";
import React, { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

function VideoMeeting() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { id } = useParams();
  const location = useLocation();
  const { session } = useSelector((state) => state.packs);
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");
  useEffect(() => {
    dispatch(fetchSessionAction(id));
  }, [dispatch, id]);

  return (
    <div>
      <JitsiMeeting
        domain={"meeting.intellect.tn"}
        roomName={session.name.replace(/\s+/g, "_")}
        jwt={token}
        configOverwrite={{
          startWithAudioMuted: true,
          disableModeratorIndicator: true,
          startScreenSharing: true,
          enableEmailInStats: false,
          // Enable waiting room feature for participants
          startWithVideoMuted: true,
          requireDisplayName: true, // Require users to enter a display name
          prejoinPageEnabled: true, // Show pre-join page (participants wait until moderator joins)
          disableInviteFunctions: true, // Optional: Disable invite functions
        }}
        interfaceConfigOverwrite={{
          DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
          DEFAULT_REMOTE_DISPLAY_NAME: "Guest", // Display name for anonymous users
        }}
        getIFrameRef={(iframeRef) => {
          iframeRef.style.height = "91vh";
        }}
      />
    </div>
  );
}

export default VideoMeeting;
